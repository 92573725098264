.dropdown {
  width: 15% !important;
  font-size: 14px !important;
}

.table {
  width: 100% !important;
}

.maintable {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 575px), (max-height: 600px) {
  .dropdown {
    width: 40%;
  }

  /* .navbarshow{
    display: inline-block !important;
  } */

  .tabresponsive {
    font-size: 14px !important;
    width: 100%;
    height: 60px !important;
  }
}

.btnmock {
  background-color: aliceblue !important;
}

.btnmock:hover {
  box-shadow: none !important;
  transform: none !important;
}

/* .navbarshow{
  display: none !important;
} */

/* .scrollable-section {
  max-height: 410px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

/* .scrollable-section::-webkit-scrollbar {
  display: none;
} */

.innersections {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.css-1n6sfyn-MenuList {
  font-size: 15px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.arrow-item {
  margin: 0 5px;
  /* Add some space between arrows */
}

.arrow-link {
  background-color: #1976d2;
  color: rgb(0, 0, 0);
  border-radius: 50%;
  /* Makes it circular */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  /* Font size for arrows */
  cursor: pointer;
}

.arrow-link:hover {
  background-color: #1565c0;
  /* Darker shade on hover */
}

.disabled .arrow-link {
  background-color: #cccccc;
  /* Gray color for disabled state */
  pointer-events: none;
}

/* .active{
  border: 1px solid black;
  padding: 0px 20px;
  background-color: #CCCCCC;
  border-radius: 10%;
} */

.usericon {
  font-size: 22px !important;
}

.css-1wdy9hr-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: none !important;
}

.css-1lhv1nx-MuiButtonBase-root-MuiMenuItem-root {
  min-width: 10px !important;
}

.css-11nv1yw-muiinputbase-input-muioutlinedinput-input.mui-disabled {
  -webkit-text-fill-color: black !important;
}

.text-stroke,
.text-stroke:disabled {
  -webkit-text-fill-color: black !important;
  /* Fill the text with red */
}

.highLightText {
  background-color: #e8e9f3 !important;
  border: solid 1px #d2d6da;
  font-size: 0.875rem !important;
  height: 200px;
  overflow: scroll;
}

.css-18xtq41-MuiGrid-root {
  -webkit-justify-content: left !important;
  justify-content: left !important;
}

.css-100luk-MuiGrid-root {
  margin-left: 60px !important;
}

.css-o2eb0d {
  margin-left: -150px !important;
}

.css-170zuf0 {
  margin-left: 15px !important;
}

.css-wgs3ri-MuiGrid-root {
  flex-basis: 42.667% !important;
  max-width: 43.666667% !important;
  height: 100vh !important;
}
.css-8zg5wk { 
  background-image: url("./assets/HDBG.png");
  /* background-size: 100% 100% !important; */
  background-size: cover !important;
  background-position: 0px 0px 0px 50px !important;
  background-repeat: no-repeat !important;
}


.source{
  border: 1px solid red !important;
}

/* .input-tracker-progress:disabled{
  background: green !important;
} */

.input-tracker-progress {
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 2px;
  outline: none;
  background: linear-gradient(to right, green 0%, green var(--progress), rgb(211, 210, 210) var(--progress), rgb(212, 211, 211) 100%);
  transition: 'background 0.2s ease-in-out';
  cursor: not-allowed;
}

.input-tracker-progress::-webkit-slider-thumb {
  appearance: none;
  display: none; /* Hide the draggable thumb */
}

